import React from "react";
import { graphql } from "gatsby";
import { parseArticleData } from "../parsers";
import StaticPage from "../components/StaticPage";

const ArticleTemplate = ({ data }) => {
  const language = "pl";
  const { title, author, html } = parseArticleData({
    markdownRemark: data[language],
  });
  return (
    <StaticPage title={title} author={author}>
      <div
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </StaticPage>
  );
};

export default ArticleTemplate;

export const query = graphql`
  query($path: String!) {
    pl: markdownRemark(fields: { slug: { eq: $path } }) {
      ...ArticleData
    }
  }
`;
