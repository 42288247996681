export const parseArticleData = (data) => {
	let { author, title } = data.markdownRemark.frontmatter
	const { slug } = data.markdownRemark.fields
	const { html } = data.markdownRemark
	return { author, title, html, slug}
}

export const parseNewsData = (data) => {
	let { title, tags } = data.markdownRemark.frontmatter
	const { slug } = data.markdownRemark.fields
	const { html } = data.markdownRemark
	return { title, html, slug, tags}
}

export const parseLabsData = (data) => {
	let { title, tag } = data.markdownRemark.frontmatter
	const { slug } = data.markdownRemark.fields
	const { html } = data.markdownRemark
	return { title, html, slug, tag}
}

export const parseStaticPageData = (data) => {
	let { title } = data.markdownRemark.frontmatter
	const { slug } = data.markdownRemark.fields
	const { html } = data.markdownRemark
	return { title, html, slug}
}
