import * as React from "react";
import { css } from "@emotion/core";
import { Link } from 'gatsby'

import ArticleComponent from "./ArticleComponent";
import TextWrapper from "./TextWrapper";

const linkTagStyle = theme => css`
  ${theme.typography.small};
  display: inline-block;
  background-color: ${theme.colors.lightGray};
  border-radius: 3px;
  padding: 4px;
  margin: 2px;
  line-height: 1.2rem;
  text-decoration: none;
`

const authorStyle = (theme) => css`
  margin-top: 0;
  color: ${theme.colors.gray};
`;

const titleStyle = css`
  margin-bottom: 2px;
`;

const articleStyle = (theme) => css`
  img {
    max-width: 100%;
  }
  .gatsby-resp-image-figcaption {
    ${theme.typography.photoDescription};
    margin-top: 5px;
    a {
      color: inherit;
    }
  }
`;

const StaticPage = ({ title, author, children, tags, preview = false }) => {
  return (
    <ArticleComponent title={title} preview={preview}>
      <TextWrapper>
        <article css={articleStyle}>
          <h1 css={titleStyle}>{title}</h1>
          {author && <p css={authorStyle}>{author}</p>}
          {tags && <div>
            {tags.map(tag => <Link css={linkTagStyle} to={`/aktualnosci/${tag}`}>{tag}</Link>)}
          </div>}
          {children}
        </article>
      </TextWrapper>
    </ArticleComponent>
  );
};

export default StaticPage;
